/** @jsx jsx */

import { useRef, useCallback } from "react"
import {
  Box,
  Heading,
  SimpleGrid,
  Stack,
  Flex,
  Text,
  useDisclosure,
  List,
  Link
} from "@chakra-ui/core"
import { jsx, css } from "@emotion/core"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import ServicesItem from "../components/ServicesItem"
import HowWeWorkStep from "../components/HowWeWorkStep"
import CasesItem from "../components/CasesItem"
import ClientListItem from "../components/ClientListItem"
import LightHoverButton from "../components/chakraOverrides/LightHoverButton"
import ScrollerButton from "../components/chakraOverrides/ScrollerButton"
import makeBackgroundImageArray from "../helpers/makeBackgroundImageArray"
import ListItem from "../components/contentfulRendering/ListItem"
import { MdDirectionsRailway } from 'react-icons/md'

const ClientsScrollByHowMuch = {
  x: 200,
  y: 0,
}

export default props => {
  const clientsListRef = useRef()
  const casesListRef = useRef()
  const scrollCases = useCallback(
    direction => {
      const widthOfCase = casesListRef.current.children[0].getBoundingClientRect()
        .width
      casesListRef.current.scrollBy(
        direction === "right" ? widthOfCase : -widthOfCase,
        0
      )
    },
    [casesListRef]
  )
  const { onOpen, isOpen, onClose } = useDisclosure()
  const intl = useIntl()
  return (
    <Layout
      location={props.location}
      bg={makeBackgroundImageArray("about")}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <Box pt={12} maxW="800px" my={20}>
        <Heading fontSize="6xl" as="h1" fontWeight="bold">
          <FormattedMessage id="about.heading" />
        </Heading>
        <Text fontSize="lg" my={3} maxW="600px">
          <FormattedMessage id="about.subheading_1" />
        </Text>
        <Text fontSize="lg" my={3} maxW="600px">
          <FormattedMessage id="about.subheading_2" />
        </Text>
      </Box>
      <Box pt={12} my={20}>
        <Heading fontSize="5xl" as="h2" fontWeight="bold" my={4}>
          <FormattedMessage id="about.more_about" />
        </Heading>
        <SimpleGrid columns={[1, null, 2, null]} spacing={3}>
          <Text>
            <FormattedMessage id="about.more_about_text.0" />
          </Text>
          <Text>
            <FormattedMessage id="about.more_about_text.1" />
          </Text>
        </SimpleGrid>
      </Box>
      <Box my={8}>
        <Heading fontSize="4xl" fontWeight="bold" as="h2">
          <FormattedMessage id="homepage.what_we_provide" />
        </Heading>
        <SimpleGrid
          columns={[1, null, 2, 3]}
          spacing={4}
          my={4}
          alignItems="center"
        >
          {[MdDirectionsRailway, "person", "searchbox", "rotatebox", "corn"].map(
            (icon, num) => (
              <ServicesItem
                icon={icon}
                text={intl.formatMessage({
                  id: `homepage.services.${num}.title`,
                })}
                description={intl.formatHTMLMessage({
                  id: `homepage.services.${num}.description`,
                })}
                placeOrderText={intl.formatMessage({
                  id: `homepage.place_order`,
                })}
                onPlaceOrder={onOpen}
              />
            )
          )}
        </SimpleGrid>
      </Box>
      <Box my={8} border="7px solid white" p={4}>
        <Heading fontSize="2xl" fontWeight="bold" mb={3}>
          <FormattedMessage id="homepage.partners" />
        </Heading>
        <Flex alignItems="center">
          <ScrollerButton
            icon="chevron-left"
            onClick={() =>
              clientsListRef.current.scrollBy(
                -ClientsScrollByHowMuch.x,
                ClientsScrollByHowMuch.y
              )
            }
          />
          <Stack
            isInline
            overflowX="auto"
            alignItems="stretch"
            mx={3}
            py={2}
            ref={clientsListRef}
            css={css`
              scroll-behavior: smooth;
            `}
            role="list"
            as="pre"
          >
            <ClientListItem
              name="ТОВ «Транссервіс – М»"
              image={props.data.tcm.childImageSharp.fixed}
              href="http://ts-m.com.ua/page/page17.html"
            />
            <ClientListItem
              name="ТОВ «ЗЕРНОЕКСПОРТ 1»"
              image={props.data.threee1.childImageSharp.fixed}
            />
            <ClientListItem
              name="АТ «Укрзалізниця»"
              image={props.data.y3.childImageSharp.fixed}
              href="https://www.uz.gov.ua/"
            />
            <ClientListItem
              name={`Асоціація "Український логістичний альянс"`}
              image={props.data.yna.childImageSharp.fixed}
              href="https://ula-online.org/ua"
            />
            <ClientListItem
              name={`ТОВ "5ПІЕЛЬ"`}
              image={props.data.fiveplandkls.childImageSharp.fixed}
              href="http://5pl.com.ua/"
            />
            <ClientListItem
              name="ТОВ «РЕЙЛ ІНСАЙДЕР»"
              image={props.data.ri.childImageSharp.fixed}
              href="https://www.railinsider.com.ua/"
            />
            <ClientListItem
              name={`ООО "ИНТЕРЛОГИСТИКС"`}
              image={props.data.tn.childImageSharp.fixed}
              href="http://www.inter-logistics.ru/"
            />
          </Stack>
          <ScrollerButton
            icon="chevron-right"
            onClick={() =>
              clientsListRef.current.scrollBy(
                ClientsScrollByHowMuch.x,
                ClientsScrollByHowMuch.y
              )
            }
          />
        </Flex>
      </Box>
      <Box my={8}>
        <Heading fontSize="2xl" fontWeight="bold" mb={3}>
          <FormattedMessage id="homepage.statutory_documents" />
        </Heading>
        <List as="ol" styleType="decimal">
          <ListItem>
            <Link href="/Виписка%20з%20ЄДРПОУ.pdf">Виписка з Єдиного державного реєстру юридичних осіб, фізичних осіб-підприємців та громадських формувань ТОВ&nbsp;ІНДІ&nbsp;ЛОДЖИСТІКС</Link>
          </ListItem>
          <ListItem>
            <Link href="/%D0%92%D0%98%D0%A2%D0%AF%D0%93%20%D0%86%D0%9D%D0%94%D0%86%20%2002.07.2020.rtf">Витяг з Єдиного державного реєстру юридичних осіб, фізичних осіб-підприємців та громадських формувань ТОВ&nbsp;ІНДІ&nbsp;ЛОДЖИСТІКС&nbsp;02.07.2020</Link>
          </ListItem>
          <ListItem>
            <Link href="/Вятяг%20платника%20ПДВ.pdf">Витяг з реєстру платників податку на додану вартість ТОВ&nbsp;ІНДІ&nbsp;ЛОДЖИСТІКС</Link>
          </ListItem>
        </List>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  {
    fiveplandkls: file(relativePath: { eq: "clients/5plandkls.jpg" }) {
      childImageSharp {
        fixed(width: 225) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    ri: file(relativePath: { eq: "clients/RI.png" }) {
      childImageSharp {
        fixed(width: 225) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    threee1: file(relativePath: { eq: "clients/ЗЕ1 logo.jpg" }) {
      childImageSharp {
        fixed(width: 225) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    tcm: file(relativePath: { eq: "clients/ТСМ.jpg" }) {
      childImageSharp {
        fixed(width: 225) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    y3: file(relativePath: { eq: "clients/YЗ.png" }) {
      childImageSharp {
        fixed(width: 225) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    yna: file(relativePath: { eq: "clients/УЛА.png" }) {
      childImageSharp {
        fixed(width: 225) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    jf: file(relativePath: { eq: "levser.jpg" }) {
      childImageSharp {
        fixed(width: 300, height: 500, cropFocus: CENTER) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    tn: file(relativePath: { eq: "clients/ТЛ.png" }) {
      childImageSharp {
        fixed(width: 225) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  }
`
