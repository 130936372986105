import React from "react"
import PropTypes from "prop-types"
import { Box, Flex, Text } from "@chakra-ui/core"

const HowWeWorkStep = props => (
  <Flex
    direction="row"
    alignItems="center"
    justifyContent="flex-start"
    bg="gray.100"
    borderRadius="full"
    m={4}
    w="clamp(20ch, 100%, 50ch)"
  >
    <Flex
      alignItems="center"
      justifyContent="center"
      fontSize="3xl"
      fontWeight="bold"
      p={3}
      bg="white"
      borderRadius="full"
      width="7rem"
      height="7rem"
      flexShrink="0"
      mr={4}
      color="purple.500"
    >
      {props.number}
    </Flex>
    <Box maxWidth="25ch" py={2} px={2} pr={4}>
      <Text fontSize="lg" fontWeight="bold">
        {props.title}
      </Text>
    </Box>
  </Flex>
)

HowWeWorkStep.propTypes = {
  number: PropTypes.element,
  title: PropTypes.string,
  description: PropTypes.string,
}

export default HowWeWorkStep
