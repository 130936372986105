/** @jsx jsx */
import PropTypes from "prop-types"
import { jsx, css } from "@emotion/core"
import { Flex, Box, Heading, Text } from "@chakra-ui/core"
import Image from "gatsby-image"

const CasesItem = props => (
  <Flex
    direction="row"
    flexShrink={0}
    width="100%"
    css={css`
      scroll-snap-align: center;
    `}
    wrap={["wrap", null, "nowrap", null]}
  >
    <Box mr={4}>
      <Image fixed={props.image} />
    </Box>
    <Flex direction="column">
      <Heading as="h3" fontSize="3xl" fontWeight="bold">
        Кейс 1: Проблема цены и сроков доставки
      </Heading>
      <Heading as="h4" fontSize="2xl" color="purple.500" my={4}>
        Дано:
      </Heading>
      <Text my={4}>
        Александр директор компании “Стаптар” уже 3 года продает аксессуары для
        телефонов в розницу, которые закупал у поставщиков из РФ.
      </Text>
      <Text my={4}>
        Когда Александр разширил розничный сбыт, его стал волновать вопрос
        сроков доставки товаров из Китая, которые превышали 40 дней и вопрос
        цены закупки товаров у производителя.
      </Text>
      <Text my={4}>Александр обратился к нам за просчетом заявки.</Text>
      <Heading as="h4" fontSize="2xl" color="green.400" my={4}>
        Решение:
      </Heading>
      <Text my={4}>
        Проанализировав рынок в Китае, мы быстро нашли производство, которое
        снизило себестоимость закупки товаров до 50% и сократило сроки доставки
        до 15 дней.
      </Text>
    </Flex>
  </Flex>
)

CasesItem.propTypes = {
  image: PropTypes.object,
}

export default CasesItem
