import React from "react"
import { Box, Text } from "@chakra-ui/core"
import Image from "gatsby-image"
import PropTypes from "prop-types"
import { css } from "@emotion/core"

const ClientListItem = ({ name, image, href }) => (
  <Box
    as="a"
    href={href}
    flexShrink={0}
    aria-label={name}
    title={name}
    bg="gray.100"
    borderRadius="lg"
    display="grid"
    gridTemplateRows="auto 4em"
    gridTemplateColumns="min-content"
    gridGap={2}
    alignItems="center"
    justifyItems="center"
    p={4}
    css={css`
      width: auto;
      &:first-of-type {
        margin-inline-start: 0;
      }
      &:last-of-type {
        margin-inline-end: 0;
      }
      margin-inline-start: 2ch;
      margin-inline-end: 2ch;
    `}
  >
    <Image fixed={image} />
    <Text textAlign="center">{name}</Text>
  </Box>
)

ClientListItem.propTypes = {
  name: PropTypes.string,
  image: PropTypes.object,
  href: PropTypes.string,
}

export default ClientListItem
