// @ts-check
import React, { Fragment } from "react"
import PropTypes from "prop-types"
import {
  Grid,
  Flex,
  Text,
  Icon,
  useDisclosure,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Box
} from "@chakra-ui/core"
import styled from "@emotion/styled"

const Container = styled(Grid)`
  transition: 150ms box-shadow ease-in-out;
  &:hover {
    box-shadow: 0px 0.1em 7px 0px rgba(0, 0, 0, 30%);
    cursor: pointer;
  }
`

const ServicesItem = props => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const onPlaceOrder = () => {
    onClose()
    props.onPlaceOrder()
  }
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{props.text}</ModalHeader>
          <ModalCloseButton />
          <ModalBody dangerouslySetInnerHTML={{ __html: props.description }} />

          <ModalFooter>
            <Button variantColor="green" w="100%" mr={3} onClick={onPlaceOrder}>
              {props.placeOrderText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Container
        height="300px"
        templateRows="4fr 2fr"
        borderColor="white"
        borderStyle="solid"
        borderWidth={7}
        onClick={onOpen}
      >
        <Flex alignItems="center" justifyContent="center">
          {typeof props.icon === "string" ? (
            <Icon
              name={props.icon}
              size="6rem"
              bg="white"
              p={5}
              borderRadius="33%"
            />
          ) : (
            <Box as={props.icon} size="6rem" bg="white" p={5} borderRadius="33%" />
          )}
        </Flex>
        <Text px={5} pt={3} fontWeight="bold" textAlign="center" fontSize="xl">
          {props.text}
        </Text>
      </Container>
    </>
  )
}

ServicesItem.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  description: PropTypes.string,
  onPlaceOrder: PropTypes.func,
  placeOrderText: PropTypes.string,
}

export default ServicesItem
