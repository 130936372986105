import React from "react"
import { IconButton } from "@chakra-ui/core"

const ScrollerButton = props => (
  <IconButton
    padding="2em 0"
    borderRadius="full"
    border="3px solid white"
    background="transparent"
    color="teal.300"
    _hover={{ background: "white" }}
    {...props}
  />
)

export default ScrollerButton
