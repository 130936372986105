import React from "react"
import { ListItem, Box } from "@chakra-ui/core"

export default ({ children }) => (
  <ListItem my={2}>
    <Box mt="-1.5em" pl="2ch">
      {children}
    </Box>
  </ListItem>
)
